import React, { FC } from 'react';
import { classes, st } from './Button.st.css';
import {
  StatesButton,
  Spinner,
  StatesButtonProps,
  StatesButtonStates,
} from 'wix-ui-tpa/cssVars';
import { Close } from '@wix/wix-ui-icons-common/on-stage';
import { ButtonDatahooks } from '../../datahooks';
import { SelectedAmountStatuses } from '../../../../types';
import Text from '../Text';

export interface ButtonProps extends Omit<StatesButtonProps, 'state'> {
  state: SelectedAmountStatuses;
}

const Button: FC<ButtonProps> = ({
  state,
  onClick,
  onNotificationEnd,
  'data-hook': dathHook,
  className,
  idleContent,
  ...rest
}) => {
  return (
    <div
      data-hook={ButtonDatahooks.MAIN}
      className={st(classes.root, {
        error: state === SelectedAmountStatuses.FAILURE,
      })}
    >
      <StatesButton
        idleContent={<Text className={classes.text}>{idleContent}</Text>}
        {...rest}
        data-hook={dathHook || ButtonDatahooks.STATES_BUTTON}
        className={st(className, classes.button)}
        state={state as unknown as StatesButtonStates}
        onClick={onClick}
        onNotificationEnd={onNotificationEnd}
        inProgressContent={
          <div className={classes.spinnerWrapper}>
            <Spinner
              data-hook={ButtonDatahooks.SPINNER}
              className={classes.spinner}
              isCentered
            />
          </div>
        }
        failureContent={
          <div data-hook={ButtonDatahooks.ERROR_ICON}>
            <Close size={21} className={classes.errorIcon} />
          </div>
        }
      />
    </div>
  );
};

export default Button;
