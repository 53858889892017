import { Text, TextProps } from 'wix-ui-tpa/cssVars';
import React from 'react';
import { classes, st } from './Text.st.css';

export enum TextType {
  Title = 'title',
}

type TextComponentProps = {
  type?: TextType;
} & TextProps;

const TextComponent: React.FC<TextComponentProps> = (props) => {
  const {
    type,
    tagName,
    className,
    children,
    'data-hook': dataHook,
    ...rest
  } = props;

  return (
    <Text
      className={st(
        classes.root,
        {
          ...(type ? { type } : {}),
        },
        className,
      )}
      data-hook={dataHook}
      tagName={tagName}
      {...rest}
    >
      {children}
    </Text>
  );
};

export default TextComponent;
