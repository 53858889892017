export enum AppDataHooks {
  MAIN = 'wix-tips-viewer-wrapper',
  HEADER = 'wix-tips-viewer-header',
}

export enum TextInputDatahooks {
  MAIN = 'text-input-with-currency-main',
  TEXT_FIELD = 'text-input-with-currency-text-field',
}

export enum ButtonDatahooks {
  MAIN = 'button-main',
  STATES_BUTTON = 'button-states-button',
  SPINNER = 'button-spinner',
  ERROR_ICON = 'button-error-icon',
}

export enum TipAmountSelectorDatahooks {
  MAIN = 'tip-amount-selector-main',
  BOX_SELECTION = 'tip-amount-selector-box-selection',
  BOX_SELECTION_OPTION = 'tip-amount-selector-box-selection-option',
  BOX_SELECTION_OPTION_CUSTOM = 'tip-amount-selector-box-selection-option-custom',
}

export enum CustomTipInputDatahooks {
  MAIN = 'custom-tip-input-main',
  TEXT_INPUT = 'custom-tip-input-text-input',
  BUTTON = 'custom-tip-input-button',
}

export enum BoxSelctionOptionLoaderDatahooks {
  MAIN = 'box-selection-option-loader-main',
  SPINNER = 'box-selection-option-loader-spinner',
  CHILDREN_WRAPPER = 'box-selection-option-loader-children-wrapper',
}

export enum ErrorMessageDatahooks {
  MAIN = 'error-message-main',
  ICON = 'error-message-icon',
  TEXT = 'error-message-text',
}
