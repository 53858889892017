import { useEffect } from 'react';
import { ApplicationContext, ApplicationProps } from '../useApplicationContext';
import { ApplicationStatus } from '../../../types';

export const useInitializeApplicationContext = (
  applicationProps: ApplicationProps,
): ApplicationContext => {
  const { actions, status, checkoutSlotProps } = applicationProps;

  useEffect(() => {
    if (status === ApplicationStatus.INITIALIZING && checkoutSlotProps) {
      actions.initializeWidget();
    } else if (!checkoutSlotProps) {
      actions.setCheckoutSlotProps();
    }
  }, [status, checkoutSlotProps]);

  return { ...applicationProps };
};
