import React, { FC } from 'react';
import { useEnvironment, useTranslation } from '@wix/yoshi-flow-editor';
import { classes, st } from './App.st.css';
import { AppDataHooks } from '../../datahooks';
import Text, { TextType } from '../../Components/Text';
import TipAmountSelector from '../TipAmountSelector';
import { useApplicationContext } from '../../../../core/hooks/useApplicationContext';

const App: FC = () => {
  const { t } = useTranslation();
  const { isRTL } = useEnvironment();
  const { presetInfo } = useApplicationContext();

  if (!presetInfo?.presets.length) {
    return null;
  }

  return (
    <div
      className={st(classes.root, { isRTL })}
      data-hook={AppDataHooks.MAIN}
      role="group"
      aria-labelledby={AppDataHooks.HEADER}
    >
      <Text
        tagName="h2"
        className={classes.header}
        data-hook={AppDataHooks.HEADER}
        type={TextType.Title}
        id={AppDataHooks.HEADER}
      >
        {t('app.wix-tips.widget.header')}
      </Text>
      <TipAmountSelector />
    </div>
  );
};

export default App;
