import React, { FC } from 'react';
import { WidgetProps } from '@wix/yoshi-flow-editor';
import {
  ApplicationContextProvider,
  ApplicationProps,
} from '../../../core/hooks/useApplicationContext';
import { useInitializeApplicationContext } from '../../../core/hooks/useInitializeApplicationContext';
import App from './App';

const Widget: FC<WidgetProps<ApplicationProps>> = (applicationProps) => {
  const applicationContext = useInitializeApplicationContext(applicationProps);

  return (
    <ApplicationContextProvider
      value={applicationContext}
      key="wix-tips-main-widget"
    >
      <App />
    </ApplicationContextProvider>
  );
};

export default Widget;
