import React, { FC } from 'react';
import { Preset, SelectedAmountStatuses } from '../../../../../../types';
import { useApplicationContext } from '../../../../../../core/hooks/useApplicationContext';
import { Spinner } from 'wix-ui-tpa/cssVars';
import { classes } from './Loader.st.css';
import { BoxSelctionOptionLoaderDatahooks } from '../../../../datahooks';

export interface LoaderProps {
  preset: Preset;
}

const Loader: FC<LoaderProps> = ({ preset, children }) => {
  const { selectedAmount } = useApplicationContext();

  if (
    preset.isSelected &&
    selectedAmount?.status === SelectedAmountStatuses.IN_PROGRESS
  ) {
    return (
      <div
        className={classes.root}
        data-hook={BoxSelctionOptionLoaderDatahooks.MAIN}
      >
        <Spinner
          className={classes.spinner}
          data-hook={BoxSelctionOptionLoaderDatahooks.SPINNER}
        />
      </div>
    );
  }

  return (
    <div data-hook={BoxSelctionOptionLoaderDatahooks.MAIN}>
      <div data-hook={BoxSelctionOptionLoaderDatahooks.CHILDREN_WRAPPER}>
        {children}
      </div>
    </div>
  );
};

export default Loader;
