import React, { FC } from 'react';
import { useApplicationContext } from '../../../../core/hooks/useApplicationContext';
import { classes, st } from './ErrorMessage.st.css';
import { ErrorFillSmall } from '@wix/wix-ui-icons-common/on-stage';
import Text from '../../Components/Text';
import { ErrorMessageDatahooks } from '../../datahooks';
import { useEnvironment } from '@wix/yoshi-flow-editor';

const ErrorMessage: FC<{}> = () => {
  const { errorMessage } = useApplicationContext();
  const { isRTL } = useEnvironment();
  if (!errorMessage) {
    return null;
  }

  return (
    <div
      className={st(classes.root, { isRTL })}
      data-hook={ErrorMessageDatahooks.MAIN}
    >
      <ErrorFillSmall
        data-hook={ErrorMessageDatahooks.ICON}
        className={classes.icon}
      />
      <Text
        data-hook={ErrorMessageDatahooks.TEXT}
        tagName="div"
        className={classes.errorMessage}
      >
        {errorMessage}
      </Text>
    </div>
  );
};

export default ErrorMessage;
