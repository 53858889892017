import React, { FC, useEffect } from 'react';
import { BoxSelection, BoxSelectionBoxSize } from 'wix-ui-tpa/cssVars';
import { useApplicationContext } from '../../../../core/hooks/useApplicationContext';
import { classes, st } from './TipAmountSelector.st.css';
import { PresetType, SelectedAmountStatuses } from '../../../../types';
import { useTranslation } from '@wix/yoshi-flow-editor';
import { renderPreset } from './Presets';
import { TipAmountSelectorDatahooks } from '../../datahooks';
import { getPresetById, getSelectedPreset } from '../../../../utils';
import CustomTipInput from '../CustomTipInput';
import ErrorMessage from '../ErrorMessage';

let keyPressed = false;
let lastPresetIdChanged: string;

const TipAmountSelector: FC = () => {
  const { presetInfo, selectedAmount, errorMessage, actions } =
    useApplicationContext();
  const { t } = useTranslation();

  const selectedPreset = getSelectedPreset(presetInfo?.presets);

  useEffect(() => {
    if (selectedPreset) {
      lastPresetIdChanged = selectedPreset?.id!;
      actions.selectTipAmount({
        presetId: selectedPreset?.id!,
        selectedAmount: {
          amount: selectedPreset?.amount!,
          type: selectedPreset?.amountType!,
          isCustom: selectedPreset?.type === PresetType.Custom,
          isDefault: selectedPreset?.isDefault,
        },
      });
    }
  }, []);

  useEffect(() => {
    if (
      selectedAmount?.status !== SelectedAmountStatuses.IN_PROGRESS &&
      selectedPreset?.id !== lastPresetIdChanged
    ) {
      handlePresetChange({
        id: lastPresetIdChanged,
        allowPresetSameAsLast: true,
      });
    }
  }, [selectedAmount?.status]);

  const isPresetSameAsLast = (id: string) => lastPresetIdChanged === id;

  const handlePresetChange = ({
    id,
    allowPresetSameAsLast,
  }: {
    id: string;
    allowPresetSameAsLast?: boolean;
  }) => {
    if (!allowPresetSameAsLast && isPresetSameAsLast(id) && !errorMessage) {
      return;
    } else {
      lastPresetIdChanged = id;
    }

    if (selectedAmount?.status === SelectedAmountStatuses.IN_PROGRESS) {
      return;
    }

    const presetToChange = getPresetById(presetInfo?.presets, id);

    actions.selectTipAmount({
      presetId: id,
      ...(keyPressed && { debounceApi: { wait: 500 } }),
      selectedAmount: {
        amount:
          presetToChange?.type === PresetType.Custom
            ? '0'
            : presetToChange?.amount,
        type: presetToChange?.amountType,
        isCustom: presetToChange?.type === PresetType.Custom,
        isDefault: presetToChange?.isDefault,
      },
    });

    keyPressed = false;
  };

  const isOnlyPresetCuston =
    presetInfo?.presets.length === 1 &&
    presetInfo?.presets[0].type === PresetType.Custom;

  const shouldShowCustomTipInput =
    selectedPreset?.type === PresetType.Custom || isOnlyPresetCuston;

  return (
    <div
      className={st(classes.root, {
        disabled: selectedAmount?.status === SelectedAmountStatuses.IN_PROGRESS,
      })}
      data-hook={TipAmountSelectorDatahooks.MAIN}
      onKeyDown={(e) => {
        // condition for any arrow key
        if (
          ['ArrowUp', 'ArrowDown', 'ArrowLeft', 'ArrowRight'].includes(e.key)
        ) {
          keyPressed = true;
        }
      }}
    >
      {!isOnlyPresetCuston && (
        <BoxSelection
          name="tip-amount-selector"
          data-hook={TipAmountSelectorDatahooks.BOX_SELECTION}
          className={classes.boxSelection}
          onChange={({ id }) => handlePresetChange({ id })}
          size={BoxSelectionBoxSize.small}
        >
          {presetInfo?.presets.map((preset) =>
            renderPreset({
              preset,
              t,
            }),
          )}
        </BoxSelection>
      )}
      {shouldShowCustomTipInput && <CustomTipInput />}
      <ErrorMessage />
    </div>
  );
};

export default TipAmountSelector;
